import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../model/user';
import { Response } from '../model/response';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private http: HttpClient) { }

  getAll(page: number, size: number): Observable<Response<User[]>> {
    return this.http.get<Response<User[]>>('admin/users', { params: {
      page: page.toString(),
      size: size.toString()
    }});
  }

  getAllInactive(page: number, size: number): Observable<Response<User[]>> {
    return this.http.get<Response<User[]>>('admin/users/inactive', { params: {
      page: page.toString(),
      size: size.toString()
    }});
  }

  create(user: User): Observable<Response<any>> {
    const body = new FormData();
    body.append('username', user.username);
    body.append('email', user.email);
    body.append('name', user.name);
    body.append('role', user.role);
    if (user.unit) body.append('unit', user.unit);

    return this.http.post<Response<any>>('admin/users', body);
  }

  update(user: User): Observable<Response<any>> {
    const body = new FormData();
    body.append('username', user.username);
    body.append('email', user.email);
    body.append('name', user.name);
    body.append('role', user.role);
    if (user.unit) body.append('unit', user.unit);
    return this.http.put<Response<any>>('admin/users/' + user.id, body);
  }

  delete(userId: string): Observable<Response<any>> {
    return this.http.delete<Response<any>>('admin/users/' + userId);
  }

  enable(userId: string): Observable<Response<any>> {
    return this.http.post<Response<any>>('admin/users/' + userId + '/enable', null);
  }

  disable(userId: string): Observable<Response<any>> {
    return this.http.post<Response<any>>('admin/users/' + userId + '/disable', null);
  }

  search(term: string, page:number, size: number): Observable<Response<User[]>> {
    return this.http.get<Response<User[]>>('admin/users/search', { params: {
      keyword: term,
      page: page.toString(),
      size: size.toString()
    }});
  }

  searchInactive(term: string, page:number, size: number): Observable<Response<User[]>> {
    return this.http.get<Response<User[]>>('admin/users/search', { params: {
      keyword: term,
      page: page.toString(),
      size: size.toString()
    }});
  }
}
